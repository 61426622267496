import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import photogRo from "../../images/rollovers/graphic-design/photog-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/photography/1.jpg'
import img2 from '../../images/graphic-design/photography/2.jpg'
import img3 from '../../images/graphic-design/photography/3.jpg'
import img4 from '../../images/graphic-design/photography/4.jpg'
import img5 from '../../images/graphic-design/photography/5.jpg'
import img6 from '../../images/graphic-design/photography/6.jpg'
import img7 from '../../images/graphic-design/photography/7.jpg'
import img8 from '../../images/graphic-design/photography/8.jpg'
import img9 from '../../images/graphic-design/photography/9.jpg'
import img10 from '../../images/graphic-design/photography/10.jpg'
import img11 from '../../images/graphic-design/photography/11.jpg'
import img12 from '../../images/graphic-design/photography/12.jpg'
import img13 from '../../images/graphic-design/photography/13.jpg'
import img14 from '../../images/graphic-design/photography/14.jpg'
import img15 from '../../images/graphic-design/photography/15.jpg'
import img16 from '../../images/graphic-design/photography/16.jpg'
import img17 from '../../images/graphic-design/photography/17.jpg'
import img18 from '../../images/graphic-design/photography/18.jpg'
import img19 from '../../images/graphic-design/photography/19.jpg'
import img20 from '../../images/graphic-design/photography/20.jpg'
import img21 from '../../images/graphic-design/photography/21.jpg'
import img22 from '../../images/graphic-design/photography/22.jpg'
import img23 from '../../images/graphic-design/photography/23.jpg'
import img24 from '../../images/graphic-design/photography/24.jpg'
import img25 from '../../images/graphic-design/photography/25.jpg'
import img26 from '../../images/graphic-design/photography/26.jpg'
import img27 from '../../images/graphic-design/photography/27.jpg'
import img28 from '../../images/graphic-design/photography/28.jpg'
import img29 from '../../images/graphic-design/photography/29.jpg'
import img30 from '../../images/graphic-design/photography/30.jpg'
import img31 from '../../images/graphic-design/photography/31.jpg'
import img32 from '../../images/graphic-design/photography/32.jpg'
import img33 from '../../images/graphic-design/photography/33.jpg'
import img34 from '../../images/graphic-design/photography/34.jpg'
import img35 from '../../images/graphic-design/photography/35.jpg'
import img36 from '../../images/graphic-design/photography/36.jpg'
import img37 from '../../images/graphic-design/photography/37.jpg'
import img38 from '../../images/graphic-design/photography/38.jpg'
import img39 from '../../images/graphic-design/photography/39.jpg'
import img40 from '../../images/graphic-design/photography/40.jpg'
import img41 from '../../images/graphic-design/photography/41.jpg'
import img42 from '../../images/graphic-design/photography/42.jpg'
import img43 from '../../images/graphic-design/photography/43.jpg'
import img44 from '../../images/graphic-design/photography/44.jpg'
import img45 from '../../images/graphic-design/photography/45.jpg'
import img46 from '../../images/graphic-design/photography/46.jpg'
import img47 from '../../images/graphic-design/photography/47.jpg'
import img48 from '../../images/graphic-design/photography/48.jpg'
import img49 from '../../images/graphic-design/photography/49.jpg'
import img50 from '../../images/graphic-design/photography/50.jpg'
import img51 from '../../images/graphic-design/photography/51.jpg'
import img52 from '../../images/graphic-design/photography/52.jpg'
import img53 from '../../images/graphic-design/photography/53.jpg'
import img54 from '../../images/graphic-design/photography/54.jpg'
import img55 from '../../images/graphic-design/photography/55.jpg'
import img56 from '../../images/graphic-design/photography/56.jpg'
import img57 from '../../images/graphic-design/photography/57.jpg'
import img58 from '../../images/graphic-design/photography/58.jpg'
import img59 from '../../images/graphic-design/photography/59.jpg'
import img60 from '../../images/graphic-design/photography/60.jpg'
import img61 from '../../images/graphic-design/photography/61.jpg'
import img62 from '../../images/graphic-design/photography/62.jpg'
import img63 from '../../images/graphic-design/photography/63.jpg'
import img64 from '../../images/graphic-design/photography/64.jpg'
import img65 from '../../images/graphic-design/photography/65.jpg'
import img66 from '../../images/graphic-design/photography/66.jpg'
import img67 from '../../images/graphic-design/photography/67.jpg'
import img68 from '../../images/graphic-design/photography/68.jpg'
import img69 from '../../images/graphic-design/photography/69.jpg'
import img70 from '../../images/graphic-design/photography/70.jpg'
import img71 from '../../images/graphic-design/photography/71.jpg'
import img72 from '../../images/graphic-design/photography/72.jpg'
import img73 from '../../images/graphic-design/photography/73.jpg'
import img74 from '../../images/graphic-design/photography/74.jpg'
import img75 from '../../images/graphic-design/photography/75.jpg'
import img76 from '../../images/graphic-design/photography/76.jpg'
import img77 from '../../images/graphic-design/photography/77.jpg'
import img78 from '../../images/graphic-design/photography/78.jpg'
import img79 from '../../images/graphic-design/photography/79.jpg'
import img80 from '../../images/graphic-design/photography/80.jpg'
import img81 from '../../images/graphic-design/photography/81.jpg'
import img82 from '../../images/graphic-design/photography/82.jpg'
import img83 from '../../images/graphic-design/photography/83.jpg'
import img84 from '../../images/graphic-design/photography/84.jpg'
import img85 from '../../images/graphic-design/photography/85.jpg'
import img86 from '../../images/graphic-design/photography/86.jpg'
import img87 from '../../images/graphic-design/photography/87.jpg'
import img88 from '../../images/graphic-design/photography/88.jpg'
import img89 from '../../images/graphic-design/photography/89.jpg'
import img90 from '../../images/graphic-design/photography/90.jpg'
import img91 from '../../images/graphic-design/photography/91.jpg'
import img92 from '../../images/graphic-design/photography/92.jpg'
import img93 from '../../images/graphic-design/photography/93.jpg'
import img94 from '../../images/graphic-design/photography/94.jpg'
import img95 from '../../images/graphic-design/photography/95.jpg'
import img96 from '../../images/graphic-design/photography/96.jpg'
import img97 from '../../images/graphic-design/photography/97.jpg'
import img98 from '../../images/graphic-design/photography/98.jpg'
import img99 from '../../images/graphic-design/photography/99.jpg'
import img100 from '../../images/graphic-design/photography/100.jpg'
import img101 from '../../images/graphic-design/photography/101.jpg'
import img102 from '../../images/graphic-design/photography/102.jpg'
import img103 from '../../images/graphic-design/photography/103.jpg'
import img104 from '../../images/graphic-design/photography/104.jpg'
import img105 from '../../images/graphic-design/photography/105.jpg'
import img106 from '../../images/graphic-design/photography/106.jpg'
import img107 from '../../images/graphic-design/photography/107.jpg'
import img108 from '../../images/graphic-design/photography/108.jpg'
import img109 from '../../images/graphic-design/photography/109.jpg'
import img110 from '../../images/graphic-design/photography/110.jpg'
import img111 from '../../images/graphic-design/photography/111.jpg'
import img112 from '../../images/graphic-design/photography/112.jpg'
import img113 from '../../images/graphic-design/photography/113.jpg'
import img114 from '../../images/graphic-design/photography/114.jpg'
import img115 from '../../images/graphic-design/photography/115.jpg'
import img116 from '../../images/graphic-design/photography/116.jpg'
import img117 from '../../images/graphic-design/photography/117.jpg'
import img118 from '../../images/graphic-design/photography/118.jpg'
import img119 from '../../images/graphic-design/photography/119.jpg'
import img120 from '../../images/graphic-design/photography/120.jpg'
import img121 from '../../images/graphic-design/photography/121.jpg'
import img122 from '../../images/graphic-design/photography/122.jpg'
import img123 from '../../images/graphic-design/photography/123.jpg'
import img124 from '../../images/graphic-design/photography/124.jpg'
import img125 from '../../images/graphic-design/photography/125.jpg'
import img126 from '../../images/graphic-design/photography/126.jpg'
import img127 from '../../images/graphic-design/photography/127.jpg'
import img128 from '../../images/graphic-design/photography/128.jpg'
import img129 from '../../images/graphic-design/photography/129.jpg'
import img130 from '../../images/graphic-design/photography/130.jpg'
import img131 from '../../images/graphic-design/photography/131.jpg'
import img132 from '../../images/graphic-design/photography/132.jpg'
import img133 from '../../images/graphic-design/photography/133.jpg'

export default function Photography() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, 
        img15, img16, img17, img18, img19,img20, img21, img22, img23, img24, img25, img26, img27, img28, 
        img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40,
        img41, img42, img43, img44, img45, img46, img47, img48, img49, img50, img51, img52, img53, 
        img54, img55, img56, img57, img58,img59, img60, img61, img62, img63, img64, img65, img66, img67, 
        img68, img69, img70, img71, img72, img73, img74, img75, img76, img77, img78, img79,
        img80, img81, img82, img83, img84, img85, img86, img87, img88, img89, img90, img91,
        img92, img93, img94, img95, img96, img97, img98, img99, img100, img101, img102, img103,
        img104, img105, img106, img107, img108, img109, img110, img111, img112, img113, img114, img115,
        img116, img117, img118, img119, img120, img121, img122, img123, img124, img125, img126, img127,
        img128, img129, img130, img131, img132, img133
    ]
    return (
        <GalleryBackground title="Photography" imgSrc={photogRo} >
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}